// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

$primary: #0074d4;

@import "bootstrap/scss/bootstrap";

/* ... */
body {
  background-color: #f5f5f5; /* Replace with your desired light grey color */
}
label {
  font-size: 25px; /* Adjust the font size as desired */
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.transparent-button {
  background-color: transparent;
  border: solid 1px;
  border-color: #000;
  color: #000; /* Or whichever color you want the text to be */
  transition: all 0.3s ease-in-out;
}

.transparent-button:hover {
  border-color: #000;
}

.divider {
  border: none;
  border-top: 1px solid grey;
}



.feedback_field.form-control {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 10%;
  border: 2px solid blue;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}
.dropdown-toggle:focus {
  outline: none !important;
}
.feedback_field.form-control {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 10%;
  border: 2px solid blue;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}
.feedback_field2.form-control {
  width: 40%;
  margin: 0 auto;
  margin-bottom: 10%;
  border: 2px solid blue;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.match_score_field.form-control {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 10%;
  border: 2px solid blue;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

/* ... */


.feedback_field.form-control.loading,
.feedback_field2.form-control.loading,
.match_score_field.form-control.loading {
  animation: glowing-border 2s infinite;
}

.match-feedback-row > div:not(:last-child) {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

@keyframes glowing-border {
  0% {
    border-color: $primary;
    box-shadow: 0 0 0 0 rgba(0, 0, 255, 0.3);
  }
  50% {
    border-color: rgba(0, 0, 255, 0.5);
    box-shadow: 0 0 30px 20px rgba(30, 85, 169, 0.5);
  }
  100% {
    border-color: $primary;
    box-shadow: 0 0 0 0 rgba(0, 0, 255, 0.3);
  }
}





textarea:focus {
  border: 4px solid purple;
  outline: none;
}

button,
.btn {
  width: 20%;
  margin-top: 25px;
  &:hover {
    background-color: darken(blue, 10%);
  }
}

.match-feedback-row {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 576px) {
  .match-feedback-row .col-md-6 {
    flex: 100%;
    max-width: 100%;
  }
}
#matches {
  background: linear-gradient(90deg, purple, violet);
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
}

#match_text::before {
  content: "Matches: ";
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, #b080e0, #d2a4ff);
  -webkit-background-clip: text;
  color: transparent;
  z-index: -1;
}



// .feedback_field.form-control {
//   width: 90%; /* Adjust the width as per your preference */
//   margin: 0 auto; /* Add margin to center the textarea horizontally */
//   margin-bottom: 10% ; 
// }
// .match_score_field.form-control {
//   width: 50%; /* Adjust the width as per your preference */
//   height: 70%;
//   margin: 0 auto;
//   margin-bottom: 10%;  /* Add margin to center the textarea horizontally */
// }

// textarea {
//   border: 2px solid purple; /* purple border */
//   box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* reduced box shadow */
// }

// textarea:focus {
//   border: 4px solid purple; /* thicker and bolder border on focus */
//   outline: none; /* remove the default outline that browsers apply on focus */
// }
// button, .btn {
//   width: 20%;
//   margin-top: 25px; // or any space you want

//   &:hover {
//     background-color: darken(blue, 10%);
//   }
// }

// .match-feedback-row {
//   display: flex;
//   flex-wrap: wrap;
// }

// @media (max-width: 576px) {
//   .match-feedback-row .col-md-6 {
//       flex: 100%;
//       max-width: 100%;
//   }
// }





// // Option 2
// //
// // Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// // Toggle global options
// $enable-gradients: true;
// $enable-shadows: true;

// $offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

// // Customize some defaults
// $body-color: rgb(187, 48, 48);
// $body-bg: rgb(189, 33, 33);
// $border-radius: .4rem;
// $success: #7952b3;


// // Required
// @import "bootstrap/scss/functions";
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/maps";
// @import "bootstrap/scss/mixins";
// @import "bootstrap/scss/utilities";
// @import "bootstrap/scss/root";
// @import "bootstrap/scss/reboot";

// @import "bootstrap/scss/type";
// // @import "bootstrap/scss/images";
// @import "bootstrap/scss/containers";
// @import "bootstrap/scss/grid";
// // @import "bootstrap/scss/tables";
// // @import "bootstrap/scss/forms";
// @import "bootstrap/scss/buttons";
// @import "bootstrap/scss/transitions";
// @import "bootstrap/scss/dropdown";
// // @import "bootstrap/scss/button-group";
//  @import "bootstrap/scss/nav";
// // @import "bootstrap/scss/navbar"; // Requires nav
// // @import "bootstrap/scss/card";
// // @import "bootstrap/scss/breadcrumb";
// // @import "bootstrap/scss/accordion";
// // @import "bootstrap/scss/pagination";
// // @import "bootstrap/scss/badge";
// // @import "bootstrap/scss/alert";
// // @import "bootstrap/scss/progress";
// // @import "bootstrap/scss/list-group";
// @import "bootstrap/scss/close";
// // @import "bootstrap/scss/toasts";
// @import "bootstrap/scss/modal"; // Requires transitions
// // @import "bootstrap/scss/tooltip";
// // @import "bootstrap/scss/popover";
// // @import "bootstrap/scss/carousel";
// // @import "bootstrap/scss/spinners";
// @import "bootstrap/scss/offcanvas"; // Requires transitions
// // @import "bootstrap/scss/placeholders";

// // Helpers
// // @import "bootstrap/scss/helpers";

// // Utilities
// @import "bootstrap/scss/utilities/api";


// //
// // Custom styles
// //

// @import "icon-list";

// body {
//   padding: 1.5rem;
// }
// textarea {
//   width: 100%;
//   height: 100px; // Adjust this to change the height of the boxes
//   border-radius: 0;
// }
